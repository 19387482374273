import { useState, useEffect } from "react"

/**
 * Get only one window listener for resize event
 */
const withOneEventListener = () => {
    let position = 0
    let listeners = new Array<{
        pos: number
        fn: () => void
    }>()

    window.addEventListener("resize", () => {
        listeners.forEach(elm => elm.fn())
    })

    const subscribe = (fnListener: () => void) => {
        const listenerObject = {
            pos: position++,
            fn: fnListener,
        }
        listeners = [...listeners, listenerObject]

        const unsubscribe = () => {
            listeners = listeners.filter(elm => elm.pos !== listenerObject.pos)
        }

        return unsubscribe
    }

    return subscribe
}

const subscribeResize = withOneEventListener()

const getWindowWidth = () => window.innerWidth

/**
 * Get window width
 */
export const useWindowWidth = () => {
    const [width, setWidth] = useState<number>(getWindowWidth())

    useEffect(() => {
        const unsubscribe = subscribeResize(() => {
            setWidth(getWindowWidth())
        })
        return () => {
            unsubscribe()
        }
    }, [])

    return width
}
