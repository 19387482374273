import { useEffect } from "react"

export const useKeyPressed = (
    key: string,
    pressed: () => void,
    detect: boolean = true,
) => {
    useEffect(() => {
        if (!detect) {
            return
        }

        const handleKeyPressed = (e: KeyboardEvent) => {
            if (e.key === key) {
                pressed()
            }
        }

        window.addEventListener("keydown", handleKeyPressed)

        return () => {
            window.removeEventListener("keydown", handleKeyPressed)
        }
    }, [key, pressed, detect])
}
