import { API_MAIN_SERVER_URL } from "config/globals"
import Axios from "axios"
import { getToken } from "helpers/jwt/get-token"

export const getAxiosConnectedInstance = () => {
    const token = getToken()

    if (!token) {
        throw Error("You are not connected")
    }

    return Axios.create({
        baseURL: API_MAIN_SERVER_URL,
        headers: {
            authorization: `Bearer ${token}`,
        },
    })
}
